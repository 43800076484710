import Bycarddeck from "./Bycarddeck";
import Bycarousel from "./Bycarousel";
import Chooseus from "./Chooseus";

const Home = () => {
    return (
        <div className="container-fluid">
            <Bycarousel/>
            <Bycarddeck/>
            <Chooseus/>
        </div>
    );
};

export default Home;